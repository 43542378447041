// extracted by mini-css-extract-plugin
export var column = "BannerReasons__column__xgG6f";
export var description = "BannerReasons__description__qAgCZ";
export var flex = "BannerReasons__flex__jfvUQ";
export var flexColumn = "BannerReasons__flexColumn__BRTrN";
export var gap1 = "BannerReasons__gap1__HOPLj";
export var gap2 = "BannerReasons__gap2__F7D4Y";
export var gap3 = "BannerReasons__gap3__Lfeey";
export var gap4 = "BannerReasons__gap4__UAFBP";
export var gap5 = "BannerReasons__gap5__uCi3b";
export var reasonsRow = "BannerReasons__reasonsRow__TxtuE";
export var root = "BannerReasons__root__kVIJX";
export var row = "BannerReasons__row__CuwYx";
export var subtitle = "BannerReasons__subtitle__iddRv";
export var title = "BannerReasons__title__w7Kt4";